import { useContext, useReducer, useState } from 'react';
import { SiteContext } from '@store/Provider';
import { useMutation } from '@apollo/client';
import { getValidationSchema } from '@helpers/getValidationSchema';
import { LOGIN, REGISTER_USER } from '@apolloClient/mutations';
import { apolloErrors } from '@helpers/constants';
import { registerInitialState } from '@helpers/constants';
import { authReducer } from '@helpers/authReducer';
import { navigate } from 'gatsby';

export const useUserRegister = (userRegisterFormErrorMessages) => {
  const { isLoggedIn, setIsLoggedIn } = useContext(SiteContext);
  const [errorMessage, setErrorMessage] = useState([]);
  const [state, dispatch] = useReducer(authReducer, registerInitialState);
  const [apolloError, setApolloError] = useState('');
  const [registerUser, { loading }] = useMutation(REGISTER_USER);
  const [login, { loading: loginLoading }] = useMutation(LOGIN);

  const validationSchema = getValidationSchema(
    ['username', 'password', 'email', 'repeatPassword', 'confirmation'],
    userRegisterFormErrorMessages,
  );

  const submitFormHandler = async (submitEvent) => {
    submitEvent.preventDefault();
    setErrorMessage([]);
    setApolloError('');
    try {
      await validationSchema.validate(state, { abortEarly: false });
      await registerUser({
        variables: {
          username: state.username,
          email: state.email,
          password: state.password,
        },
      });
      const response = await login({
        variables: {
          username: state.username,
          password: state.password,
        },
      });

      localStorage.setItem('token', response.data.login.authToken);
      localStorage.setItem('refreshToken', response.data.login.refreshToken);
      localStorage.setItem('username', response.data.login.user.username);
      localStorage.setItem('email', response.data.login.user.email);
      setIsLoggedIn(true);
      navigate('/blog');
    } catch (error) {
      if (error.name === 'ApolloError') {
        setApolloError(apolloErrors[error.message]);
      }

      error.inner &&
        setErrorMessage(
          error.inner.map((innerError) => ({
            [innerError.path]: innerError.message,
          })),
        );
    }
  };

  return {
    isLoggedIn,
    errorMessage,
    dispatch,
    apolloError,
    loading,
    loginLoading,
    submitFormHandler,
    state,
  };
};
