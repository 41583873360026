// Action types
export const SET_EMAIL = 'email';
export const SET_PASSWORD = 'password';
export const SET_RE_PASSWORD = 'repeatPassword';
export const SET_USERNAME = 'username';
export const SET_CHECKBOX = 'confirmation';

// Reducer function
export const authReducer = (state, action) => {
  switch (action.type) {
    case SET_EMAIL:
      return { ...state, email: action.payload };
    case SET_RE_PASSWORD:
      return { ...state, repeatPassword: action.payload };
    case SET_PASSWORD:
      return { ...state, password: action.payload };
    case SET_USERNAME:
      return { ...state, username: action.payload };
    case SET_CHECKBOX:
      return { ...state, confirmation: action.payload };
    default:
      return state;
  }
};
