import PropTypes from 'prop-types';
import React from 'react';
import Input from '@components/atoms/Input';
import Checkbox from '@components/atoms/Checkbox';
import Button from '@components/atoms/Buttons/Button';
import LinkButton from '@components/atoms/Buttons/LinkButton';
import styles from './AuthForm.module.scss';

const AuthForm = ({
  submitFormHandler,
  formTitle,
  formContent,
  sideButton,
  sideLabel,
  changeHandler,
  state,
  loading,
  errorMessage,
  checkedError,
  apolloError,
  isRegistrationForm,
  forgottenPasswordLabel,
  toggleForgottenPassword,
  isForgottenPassword,
  withoutPaddingTop,
}) => (
  <div className={`${styles.wrapper} ${!withoutPaddingTop ? 'pt-[90px]' : ''}`}>
    <div className={styles.formWrapper}>
      <form onSubmit={submitFormHandler} className={styles.form}>
        <i className={`icon-profile ${styles.icon}`} />
        {!isForgottenPassword && <p className="h2 font-500">{formTitle}</p>}
        <div className={styles.inputWrapper}>
          <Input
            width="w-full"
            placeholder={formContent.email}
            onChange={changeHandler}
            errorMessage={errorMessage}
            name="email"
          />
          {isRegistrationForm && (
            <Input
              width="w-full"
              placeholder={formContent.username}
              onChange={changeHandler}
              errorMessage={errorMessage}
              name="username"
            />
          )}
          {!isForgottenPassword && (
            <Input
              width="w-full"
              placeholder={formContent.password}
              onChange={changeHandler}
              type="password"
              errorMessage={errorMessage}
              name="password"
            />
          )}
          {isRegistrationForm && (
            <Input
              width="w-full"
              placeholder={formContent.repeatPassword}
              type="password"
              onChange={changeHandler}
              errorMessage={errorMessage}
              name="repeatPassword"
            />
          )}
        </div>
        <div className={styles.checkboxWrapper}>
          {isRegistrationForm ? (
            <>
              <Checkbox
                name="confirmation"
                id="confirmation"
                label={formContent.checkbox}
                link={formContent.termsAndConditions}
                className="small text-left"
                checkBoxType={isRegistrationForm ? 'checkbox' : 'hidden'}
                isChecked={state.confirmation}
                handleCheckbox={changeHandler}
              />
              {checkedError && (
                <p className={styles.error}>{checkedError.confirmation}</p>
              )}
            </>
          ) : (
            <Button
              onClick={toggleForgottenPassword}
              stylesCustom="text-small text-white-100"
            >
              {!isForgottenPassword ? forgottenPasswordLabel : formTitle}
            </Button>
          )}
          <div className="text-center">
            <Button isLoading={loading} type="submit" variant="secondary">
              {formContent.button}
            </Button>
          </div>
        </div>
        {apolloError && <p className={styles.apolloError}>{apolloError}</p>}
      </form>
      <div
        className={
          isRegistrationForm ? styles.sideContent : styles.sideContentLogin
        }
      >
        <p>{sideLabel}</p>
        <LinkButton
          href={sideButton.url}
          stylesCustom="flex gap-4 items-center"
        >
          <span>{sideButton.title}</span>
          <i className="icon-interface-arrows-right right-20" />
        </LinkButton>
      </div>
    </div>
  </div>
);

AuthForm.propTypes = {
  apolloError: PropTypes.string,
  changeHandler: PropTypes.func,
  checkedError: PropTypes.shape({
    confirmation: PropTypes.string,
  }),
  errorMessage: PropTypes.array,
  forgottenPasswordLabel: PropTypes.string,
  formContent: PropTypes.shape({
    button: PropTypes.string,
    checkbox: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    repeatPassword: PropTypes.string,
    termsAndConditions: PropTypes.object,
    username: PropTypes.string,
  }),
  formTitle: PropTypes.string,
  isForgottenPassword: PropTypes.bool,
  isRegistrationForm: PropTypes.bool,
  loading: PropTypes.bool,
  sideButton: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  sideLabel: PropTypes.string,
  state: PropTypes.shape({
    confirmation: PropTypes.bool,
  }),
  submitFormHandler: PropTypes.func,
  toggleForgottenPassword: PropTypes.func,
  withoutPaddingTop: PropTypes.bool,
};

export default AuthForm;
