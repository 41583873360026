import React, { useMemo } from 'react';
import HeroImage from '@images/home_page_hero.webp';
import HeroImageMobile from '@images/mobile_home_page_hero-min.webp';
import Image from '@components/molecules/ImageGatsby';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import useToggle from '@hooks/useToggle';
import SEO from '@components/molecules/SEO';
import ShareButton from '@components/molecules/ShareButton/ShareButton';
import TopFixedSection from '@components/organisms/TopFixedSection';
import Layout from '@layouts/Layout';
import { graphql, navigate } from 'gatsby';
import AuthForm from '@components/organisms/AuthForm';

import { useUserRegister } from '@hooks/useUserRegister';
import { useDisablePageScroll } from '@hooks/useDisablePageScroll';

const RegisterPage = ({
  data: {
    allWp: {
      nodes
    },
    wp: {
      siteSettings: {
        siteSettings: {
          menuText,
          menuLogo,
          userRegisterFormTitle,
          userRegisterForm,
          userRegisterFormErrorMessages,
          userRegisterLoginButton,
          userRegisterAlreadyRegisteredLabel,
        },
      },
    },
  },
}) => {
  const {
    isLoggedIn,
    errorMessage,
    dispatch,
    apolloError,
    loading,
    loginLoading,
    submitFormHandler,
    state,
  } = useUserRegister(userRegisterFormErrorMessages);

  const isMobile = useBreakpoint(breakpoints.sm);
  const { toggled, handleToggleClick } = useToggle(false);

  const checkedError = useMemo(
    () => errorMessage.find((message) => message.confirmation),
    [errorMessage],
  );

  const inputChangeHandler = ({ target: { checked, name, value } }) => {
    dispatch({
      type: name,
      payload: name === 'confirmation' ? checked : value,
    });
  };

  useDisablePageScroll();

  if (isLoggedIn) {
    return navigate('/blog');
  }

  return (
    <Layout
      translations={[]}
      currentLanguage="bg"
      lang="bg"
      hideParallax
      showFooter={false}
    >
      <SEO title="Register" translations={[]} />
      <Image image={isMobile ? HeroImageMobile : HeroImage} fill />
      <div className="h-screen backdrop-blur-[35px] backdrop-brightness-75">
        <TopFixedSection
          menuText={menuText}
          menuLogo={menuLogo}
          handleToggleClick={handleToggleClick}
          toggled={toggled}
          generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
        />
        <AuthForm
          submitFormHandler={submitFormHandler}
          changeHandler={inputChangeHandler}
          formTitle={userRegisterFormTitle}
          formContent={userRegisterForm}
          errorMessage={errorMessage}
          checkedError={checkedError}
          apolloError={apolloError}
          loading={loading || loginLoading}
          sideLabel={userRegisterAlreadyRegisteredLabel}
          sideButton={userRegisterLoginButton}
          state={state}
          isRegistrationForm
        />
        <div className="absolute left-10 md:left-40 lg:left-50 bottom-6 md:bottom-16 lg:bottom-26 z-[51]">
          <ShareButton />
        </div>
      </div>
    </Layout>
  );
};

export default RegisterPage;

export const query = graphql`
  query REGISTER_PAGE {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wp {
      siteSettings {
        siteSettings {
          userRegisterAlreadyRegisteredLabel
          userRegisterForm {
            button
            checkbox
            email
            password
            username
            repeatPassword
            termsAndConditions {
              title
              url
            }
          }
          userRegisterFormTitle
          userRegisterFormErrorMessages {
            emptyField
            invalidCheckbox
            invalidField
            requiredField
          }
          userRegisterLoginButton {
            title
            url
          }
          menuText
          menuLogo {
            altText
            publicUrl
            mediaItemUrl
          }
        }
      }
    }
  }
`;
